import React, { useState } from 'react';

import { DisplayAttachmentFile } from '~/components/molecules/DisplayAttachmentFile';
import { FullScreenImageVisualization } from '~/components/molecules/FullScreenImageVisualization';
import { useLectureMuralContext } from '~/contexts/LectureMuralContext';
import settings from '~/settings';

import { useStyles } from './styles';
import { Box, ButtonBase } from '@material-ui/core';
import FullscreenRoundedIcon from '@material-ui/icons/FullscreenRounded';
import PropTypes from 'prop-types';

export const Attachment = ({ attachment }) => {
  const classes = useStyles();

  const { lecture } = useLectureMuralContext();
  const [openImageModal, setOpenImageModal] = useState(false);

  if (attachment.type === 'image') {
    return (
      <Box className={classes.root}>
        <img
          src={`${settings.baseUrl}${attachment?.url}`}
          className={classes.image}
        />
        <ButtonBase
          disableRipple
          className={classes.openFullScreenImageButton}
          onClick={() => setOpenImageModal(true)}
        >
          <FullscreenRoundedIcon
            className={classes.openFullScreenImageButtonIcon}
          />
        </ButtonBase>

        <FullScreenImageVisualization
          attachment={attachment}
          open={openImageModal}
          closeModal={() => setOpenImageModal(false)}
          downloadable={lecture.downloadable_status || lecture.can_manage}
        />
      </Box>
    );
  } else if (attachment.type === 'document') {
    return (
      <DisplayAttachmentFile
        attachment={attachment}
        downloadable={lecture.downloadable_status || lecture.can_manage}
      />
    );
  }
};

Attachment.propTypes = {
  attachment: PropTypes.shape({
    type: PropTypes.oneOf(['image', 'document']).isRequired,
    url: PropTypes.string.isRequired
  }).isRequired
};
