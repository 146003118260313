/* eslint-disable react/prop-types */
import React, { useRef, useState } from 'react';

import { useStyles } from './styles';
import {
  Box,
  ButtonBase,
  ListItem,
  Menu,
  MenuItem,
  Typography
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CheckIcon from '@material-ui/icons/Check';

export default function Selection({
  objects,
  handleOnChange,
  defaultValue,
  selectedValue
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [paperWidth, setPaperWidth] = useState(null);
  const buttonRef = useRef();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setPaperWidth(buttonRef.current ? buttonRef.current.offsetWidth : null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <ButtonBase
        disableRipple
        onClick={handleClick}
        className={classes.button}
        ref={buttonRef}
      >
        <Typography className={classes.buttonLabel}>
          {selectedValue === ''
            ? defaultValue
            : objects.find((value) => value === selectedValue)}
        </Typography>
        <ArrowDropDownIcon
          style={{
            fontSize: '20px'
          }}
        />
      </ButtonBase>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        PaperProps={{
          style: {
            marginTop: '40px',
            minWidth: paperWidth,
            maxHeight: '40%'
          }
        }}
      >
        {objects.map((option, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              handleOnChange(option);
              handleClose();
            }}
            className={classes.item}
          >
            <Box width={'18px'}>
              {selectedValue === option && (
                <CheckIcon className={classes.icon} />
              )}
            </Box>
            <Typography className={classes.labelText}>{option}</Typography>
          </MenuItem>
        ))}
        {objects.length === 0 && (
          <ListItem className={classes.labelText}>
            {`Nenhum "${defaultValue}" disponível`}
          </ListItem>
        )}
      </Menu>
    </>
  );
}
