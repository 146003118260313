import React from 'react';

import LecturesReport from '~/components/molecules/LecturesReport';
import ReportRow from '~/components/molecules/ReportRow';
import { ReportTableAccordion } from '~/components/molecules/ReportTableAccordion';
import i18n from '~/I18n';
import { getSubjects } from '~/services/Space';

import ExerciseAverageReport from './ExerciseAverageReport';
import ExerciseGradesCountReport from './ExerciseGradesCountReport';
import FormConclusionReport from './FormConclusionReport';
import InformationListReport from './InformationListReport';
import SimplifiedSubjectsReport from './SimplifiedSubjectsReport';
import { useStyles } from './styles';
import UsersListReport from './UsersListReport';
import UsersRegisteredReport from './UsersRegisteredReport';
import PropTypes from 'prop-types';

export default function SpaceReports({ id }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ReportTableAccordion
        title="Relatórios de conclusão de mídias"
        report={LecturesReport}
        id={id}
        fetchFunction={getSubjects}
      />
      <ReportRow
        title={`Relatório de conclusão da ${i18n.t(
          'StructuresName.Space.SingularUpper'
        )}`}
        report={SimplifiedSubjectsReport}
        id={id}
      />
      <ReportRow
        title="Relatório de notas em cada exercício"
        report={ExerciseGradesCountReport}
        id={id}
      />
      <ReportRow
        title="Relatório de média dos alunos nos exercícios"
        report={ExerciseAverageReport}
        id={id}
      />
      <ReportRow
        title="Relatório de status do questionário"
        report={FormConclusionReport}
        id={id}
      />
      <ReportRow
        title="Relatório de usuários que acessaram a Plataforma"
        report={UsersListReport}
        id={id}
      />
      <ReportRow
        title={`Relatório de papéis do usuário na ${i18n.t(
          'StructuresName.Space.SingularUpper'
        )}`}
        report={UsersRegisteredReport}
        id={id}
      />
      <ReportRow
        title={`Relatório de módulos da ${i18n.t(
          'StructuresName.Space.SingularLower'
        )}`}
        report={InformationListReport}
        id={id}
      />
    </div>
  );
}

SpaceReports.propTypes = {
  id: PropTypes.string
};

SpaceReports.defaultProps = {
  id: ''
};
